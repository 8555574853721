<template>
  <header class="header">
    <div class="wrapper">
      <span v-if="$layoutData.showMenu === true" class="d-block menu" @click="toggleSidebar">
        <img v-if="sidebarCollapsedData === true" src="../assets/images/menu.svg" alt="" />
        <img v-else src="../assets/images/close.svg" alt="" />
      </span>
      <div class="logo-wrapper">
        <img
          v-if="$layoutData.accountAppType === AccountAppType.AgentSnap"
          src="../assets/images/logo-agent-snap.png"
          alt=""
        />
        <img
          v-else-if="$layoutData.accountAppType === AccountAppType.ClaimSnap"
          src="../assets/images/logo-claims-snap.png"
          alt=""
        />
      </div>
    </div>
    <div class="right-header-block">
      <img class="image-wrapper" src="../assets/images/restart.svg" alt="" @click="startOverModal?.openModal" />
      <n-divider vertical :theme-overrides="{ color: '#646464' }" />
      <img class="image-wrapper" src="../assets/images/tooltip.svg" alt="" @click="askUsAnythingModal?.openModal" />
    </div>
  </header>
  <SignUpStepsModal
    ref="startOverModal"
    :submit="{ callback: () => {}, text: 'Start Over' }"
    :cancel="{
      callback: () => {
        startOverModal?.closeModal();
      },
    }"
    header="Want to start over?"
    description="This will clear your answers and start again from the beginning"
  />
  <AskUsAnythingModal ref="askUsAnythingModal" />
</template>

<script setup lang="ts">
import { AccountAppType } from '../common/enums/account-app-type';
import SignUpStepsModal from '../components/SignUpStepsModal.vue';
import AskUsAnythingModal from '../components/AskUsAnythingModal.vue';

const startOverModal = ref<InstanceType<typeof SignUpStepsModal> | null>(null);
const askUsAnythingModal = ref<InstanceType<typeof AskUsAnythingModal> | null>(null);

const { sidebarCollapsedData, setSidebarCollapsed } = useSidebarCollapse();
const { signUpStepsLayoutData: $layoutData } = useSignUpStepsLayout();

defineProps({ menuIsUsed: { type: Boolean, required: false, default: false } });

const toggleSidebar = () => {
  setSidebarCollapsed(!sidebarCollapsedData.value);
};
</script>

<style scoped lang="scss">
.header {
  height: 76px;
  padding: 18px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $dark-gray-2;

  img {
    width: 24px;
    height: 24px;
  }
}

.right-header-block {
  max-width: 201px;
  display: flex;
  align-items: center;
  gap: 16px;
}

.menu {
  font-size: 25px;
  padding: 8px 0px 8px 0px;
  margin-right: 15px;
  cursor: pointer;
  transition: color 0.3s ease;

  &:hover {
    color: white;
  }
}

.n-divider.n-divider--vertical {
  margin: 0;
  width: 1px;
  height: 20px;
}

.wrapper {
  display: flex;
  flex-direction: row;
}

.logo-wrapper {
  margin-right: 10px;
  img {
    height: 40px;
    width: 170px;
  }
}

.image-wrapper:hover {
  cursor: pointer;
}
</style>
