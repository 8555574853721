<template>
  <SignUpStepsModal
    ref="modal"
    class="modal-wrapper"
    :submit="{ callback: onSubmit, text: 'Send' }"
    header="Ask us anything"
    description="This will clear your answers and start again from the beginning"
  >
    <Form ref="form" :rules="rules" :form-value="formValue">
      <n-form-item label="Full name" path="data.fullName" :show-require-mark="false" :label-props="{ for: 'fullName' }">
        <n-input v-model:value="formValue.data.fullName" placeholder="Full name" :input-props="{ id: 'fullName' }" />
      </n-form-item>
      <n-form-item label="Email address" path="data.email" :show-require-mark="false" :label-props="{ for: 'email' }">
        <n-input v-model:value="formValue.data.email" placeholder="Email address" :input-props="{ id: 'email' }" />
      </n-form-item>
      <n-form-item label="Message" path="data.message" :show-require-mark="false" :label-props="{ for: 'message' }">
        <n-input
          v-model:value="formValue.data.message"
          placeholder="Message"
          type="textarea"
          :autosize="{
            minRows: 4,
          }"
          :input-props="{ id: 'message' }"
        />
      </n-form-item>
    </Form>
  </SignUpStepsModal>
</template>

<script lang="ts" setup>
import type { FormRules } from 'naive-ui';
import type Form from './Form.vue';
import type SignUpStepsModal from './SignUpStepsModal.vue';

const modal = ref<InstanceType<typeof SignUpStepsModal> | null>(null);
const form = ref<InstanceType<typeof Form> | null>(null);

const initFormValue = {
  fullName: null,
  email: null,
  message: null,
};

const formValue = ref({
  data: { ...initFormValue },
});

function resetForm(): void {
  Object.assign(formValue.value.data, initFormValue);
}

const rules: FormRules = {
  data: {
    fullName: {
      required: true,
      trigger: ['blur'],
      max: 100,
      message: 'Full name is required',
    },
    email: {
      key: 'email',
      required: true,
      trigger: ['blur'],
      type: 'email',
      message: 'Email Address is required with valid format',
    },
    message: {
      required: true,
      trigger: ['blur'],
      message: 'Message is required',
    },
  },
};

const onSubmit = async () => {
  try {
    await form.value?.formRef?.validate();

    closeModal();
  } catch {}
};

const openModal = () => {
  resetForm();
  modal.value?.openModal();
};

const closeModal = () => {
  modal.value?.closeModal();
};

defineExpose({ openModal, closeModal });
</script>
