<template>
  <n-layout-sider
    bordered
    collapse-mode="width"
    class="sidebar-wrapper"
    :collapsed-width="0"
    :width="357"
    :collapsed="collapsed"
    :native-scrollbar="false"
    @collapse="collapsed = true"
  >
    <n-layout-content position="absolute" :native-scrollbar="false" :theme-overrides="{ color: 'transparent' }">
      <slot />
    </n-layout-content>
  </n-layout-sider>
</template>

<script lang="ts" setup>
import debounce from 'debounce';

const { sidebarCollapsedData: collapsed, setSidebarCollapsed } = useSidebarCollapse();

const collapsedWidth = ref<number>(104);
const sidebarPosition = ref<'static' | 'absolute'>('static');

const handleResize = (): void => {
  const isDesktop = window.innerWidth >= 768;

  collapsedWidth.value = isDesktop ? 104 : 0;
  sidebarPosition.value = isDesktop ? 'static' : 'absolute';

  setSidebarCollapsed(!(window.innerWidth >= 1200));
};

const resizeEventHandler = debounce(handleResize, 50);

onMounted(() => {
  handleResize();
  window.addEventListener('resize', resizeEventHandler);
});

onUnmounted(() => {
  window.removeEventListener('resize', resizeEventHandler);
});
</script>

<style scoped lang="scss">
.sidebar-wrapper {
  height: calc(100vh - 76px);
  background-color: $dark-gray-2;
}
</style>
